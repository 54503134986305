import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230922: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-22',
  filename: '2023-09-22-selflessness.mp3',
  key: '02bdecc9-9843-4f49-906e-5f119b77b190',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Selflessness',
}
