import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230324: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-24',
  filename: '2023-03-24-self-honesty.mp3',
  key: '7258f70d-8016-4dbf-9df6-2cb98a4a3595',
  published: '2023-12-15',
  title: 'Self-Honesty',
}
