import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231103: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-03',
  filename: '2023-11-03-6-refinements-meditation-mudras.mp3',
  key: '6db86103-5cc3-4c2d-a9e7-a114984c9c80',
  published: '2023-11-29',
  title: '6 Refinements: Meditation: Mudras',
}
