import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231020: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-20',
  filename: '2023-10-20-6-refinements-vitality-science-of-thought.mp3',
  key: '3f5c5771-6616-4be6-b14c-8d40a2f16b35',
  published: '2023-12-06',
  title: '6 Refinements: Energy & Vitality: The Science of Thought',
}
