import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230303: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-03',
  filename: '2023-03-03-perfection.mp3',
  key: '27b52f88-9e27-480d-9022-2929940f1fc3',
  published: '2023-12-15',
  title: 'Perfection',
}
