import React from 'react'
import { Link } from 'react-router-dom'

import { LocationLink } from '../buddhist-page/location-link'

export function HomePage() {
  return (
    <>
      <img
        alt="Black Paw Header"
        className="rounded mx-auto img-fluid"
        src="/assets/blackpaw-banner-01.png"
      />

      <h1 className="display-1">Shared Resources</h1>

      <p>
        Dharma talks and other things from the
        {' '}<LocationLink location={'sangha-circle'}/>{' '}
        are
        {' '}<Link to={'/buddhist'}>here</Link>.
      </p>
    </>
  )
}
