import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230109: IAudioItem = {
  category: 'buddhist',
  date: '2023-01-09',
  filename: '2023-01-09-history-misc.mp3',
  key: '344687cf-6f40-4ba0-b6f3-0d85d84f901e',
  published: '2023-12-15',
  title: 'History: Odds and Ends',
}
