import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231218: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-18',
  filename: '2023-12-18-4-intents-of-practitioners.mp3',
  key: 'cff1d432-8fff-46b3-b470-61c6a2e45a9c',
  published: '2023-12-18',
  title: 'The Four Intents of Practitioners',

  notes: `
<h5>HOMEWORK:</h5>
<p>
Why do you practice?
</p>
`
}
