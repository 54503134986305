import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231117: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-17',
  filename: '2023-11-17-6-refinements-meditation-just-sit-samatha-meditation.mp3',
  key: '46f5233a-8a98-457b-9205-7968126c5fa2',
  published: '2023-11-25',
  title: '6 Refinements: Meditation: Samatha Meditation: Just Sit',

  notes: `
  <h5>Homework</h5>
  <p>
Place your meditation seat facing an unadorned wall. 
Knees should be about a foot from the wall. 
Get into a meditation posture and focus on the portion of wall that 
your eyes naturally rest on. 
Now, just sit and watch your thoughts go by. 
Just sit and let your emotions go by. 
Just sit and breathe.  
JUST SIT AND WATCH.
  </p>
 
 <h5> Handout </h5>
 
<p>GUIDED MEDITATION</p>

<p>
In your chosen posture be relaxed and upright.  
Eyes partially closed. 
Imagine your head is a balloon trying to gently rise and elongate the spine. 
Shoulders parallel with hips.
</p>

<hr />

<p>
Check posture again and adjust. You want to be erect and alert.
</p>

<hr />

<p>
Turn your attention to sensations of the breath. 
You might experience these at the tip of nose, 
cool in and warm out; 
or the movement of the belly as you breath; 
or the sound it makes going in and out. 
Bring your full attention to that area of the body.
</p>

<p>
Follow from inhale to exhale and on to the next cycle. 
</p>

<hr />

<p>
Thoughts will enter. 
Humans are thinking machines. 
Those thoughts mean you no harm. 
Treat them like a SCAM call on your phone. 
You just let them pass without engaging with them.
</p>

<hr />

<p>
You might get some narrative thoughts (a memory, a wish, a daydream, a story).  
GOOD. 
This is a win for the bodymind. 
Now let that narrative thought gently go and get back to the sensations of the breath.
</p>

<p>
Trying not to control . . . or to control.  Accept breath however it presents itself.
</p>

<hr />

<p>
Experience the Puppy Mind. 
It wants to run and play, chew this and pee on that. 
Gently tell the Puppy Mind to sit and bring attention back to the breath.
</p>

<hr />

<p>
Notice the “texture” of each in and out breath. Is it rough, smooth, jittery, deep, or?
“There is nothing you need to do. Experience this moment.”
</p>

<hr />

<p>
With each breath practice mindfulness and acceptance of how it is.
</p>

<hr />

<p>
Frisky mind? Be with it.   
Calm? Be with it.  
It is how it is.
</p>

<hr />

<p>
Judgements? 
Going well or poorly.  
Like, don’t like. 
Want, don’t want. 
Remember what D.T. Suzuki shares, 
Leave the front door and the back door of your bodymind open. 
Let whatever thoughts want to, 
to come in, 
just do not offer them any tea (tea is attention). 
They will leave.  
Now go back and experience the sensations of breathing.
</p>

<hr />

<p>
The core ideal is to keep bringing focus back to NOW. GENTLY.
Each time you bring your focus back to the breath you are training the bodymind to focus (one-point). 
</p>

<hr />

<p>
Focus again on following breathing through its cycles.
</p>

<hr />

<p>
Physical discomfort.  
DEAL WITH IT.  
Make it your object of awareness for a time.  
Physical pain should be adjusted for.  
If you have to move be mindful and move slowly and only as much as you 
need to so you relieve the pain.
</p>

<hr />

<p>
You cannot fail because whatever you experience and discover is appropriate. 
Keep developing the intention to pay attention to each moment.
</p>

<hr />

<p>
Last few minutes. 
Other objects of awareness.  
Notice contacts between body and cushion. 
Where do you end, and the cushion begin?  
Focus on sensations.
</p>

<hr />

<p>
Notice air against exposed skin.  
Experience the subtle contact.  
Feel the air.
</p>

<hr />

<p>
Now just listen to sounds in your environment.  
Labeling is not necessary, just experience.
</p>

<hr />

<p>
Now experience your space. 
Open eyes (if closed). 
Don’t move. 
Notice what is in your visual field. 
No judgement, Open Monitoring, Choiceless awareness.
</p>

<p>
Allow attention to land on whichever sensory input is the strongest.
</p>

<hr />

<p>
Note this sensory input. 
During your next meditation practice use that sensory input 
(thought, smell, sound, sight, feeling, hearing) as your object of focus.
</p>

<hr />

<p>
Now gently go back to your breathing.  
In.  
Out.  
Slowly and gently let your bodymind leave the meditative state.
</p>

 
 <h5>
 Asking Questions
 </h5>
 
 <p>
 Was there a moment when I felt really centered and especially calm?  
 If there was, ask . . .
 </p>

<ol>
<li>What was a I focused on in that moment?</li>
<li>How intense was that focus?</li>
<li>How was my breathing (slow, choppy, deep, etc.)?</li>
<li>How did my progress lead up to that experience?</li>
</ol>

<p>
If a moment like that wasn’t experienced, then ask: 
What hindered my attaining a sense of centeredness and calm?
</p>

<ol>
<li>What was a I focused on in that moment?</li>
<li>How intense was that focus?</li>
<li>How was my breathing (slow, choppy, deep, etc.)?</li>
<li>How did my progress lead up to that experience?</li>
</ol>

<p>
You must answer these questions with rigorous self-honesty.  
</p>
`,
}
