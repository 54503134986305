import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Footer } from '../footer/footer'

export function RootPage() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              alt="logo"
              className="rounded"
              src="/logo192.png"
              style={{width: '2rem', height: '2rem', marginRight: '1rem'}}
            />
            Black Paw
          </Link>
        </div>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                aria-current="page"
                className="nav-link active"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                aria-current="page"
                className="nav-link active"
                to="/buddhist"
              >
                Buddhist
              </Link>
            </li>
          </ul>
        </div>

      </nav>
      <div className="container">
        <Outlet/>
        <Footer/>
      </div>
    </>
  )
}
