import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240510: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-10',
  filename: '2024-05-10-cycling-through-samsara-2.mp3',
  key: '76d8e830-dd09-4bc4-b291-b6fe94d4afdf',
  published: '2024-05-13',
  title: 'Cycling Through Samsara, Part II',
  notes: `
<h5>Notes</h5>
The Twelve Links of Dependent Origination that can hinder the noble path, 
in this case the bike path you've chosen.  
Those links are:
<ol>
<li>ignorance</li>
<li>volitional actions</li>
<li>consciousness</li>
<li>psychophysical phenomena</li>
<li>the sense faculties</li>
<li>contact</li>
<li>sensation</li>
<li>thirst</li>
<li>clinging</li>
<li>the process of becoming</li>
<li>birth</li>
<li>and pain-and-death</li>
</ol>
`
}
