import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240223: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-23',
  filename: '2024-02-23-experience-and-sutras.mp3',
  key: '9ae76448-ff72-4ab6-9694-73d56a57911e',
  published: '2024-02-24',
  title: 'Experience / Sutras',
  notes: `
<h5>Heart Sutra (Engaged Dharma Insight Group)</h5>
<p>
Avalokiteśvara, Bodhisattva of compassion, observing deeply the refinement of wisdom, 
Prajñāpāramitā, clearly saw the emptiness of personality, thus enduring adversity and pain. 
</p>
<p>
O, Śāriputra, 
form is no other than emptiness, emptiness no other than form; 
form is exactly emptiness, emptiness exactly form; 
the same is true of feeling, perception, mental formations and consciousness. 
</p>
<p>
O, Śāriputra, 
all dharmas are forms of emptiness, 
not born, not destroyed; 
not tainted, not pure, 
not increasing, not decreasing, 
and so in emptiness there is 
no form, no feeling, no perception, no mental formations, no consciousness; 
no eyes, no ears, no nose, no tongue, no body, no mind;  
no color, no sound, no smell, no taste, no touch, no thought, no realm of sight 
and so forth until 
no realm of consciousness, 
no ignorance, no end to ignorance 
and so forth until 
no old age and death, and no end to old age and death, 
no suffering, no desire, no cessation, no path, no wisdom, no attainment. 
</p>
<p>
And so the Bodhisattva relies on the Prajñāpāramitā with 
no hindrance in the mind, 
no hindrance, therefore no fear, 
far beyond deluded thoughts, 
this is Nirvana. 
All past, present, and future Buddhas rely on the refinement of wisdom and thus attain the cultivated enlightenment. 
Therefore, 
know that the Prajñāpāramitā is the interdependent mantra, 
the interconnected mantra, 
the mantra of world making 
the mantra which relieves all suffering, 
so proclaim the Prajñāpāramitā mantra, 
proclaim the mantra and say: 
</p>
<p>
Gate! Gate! Pāragate! Pārasaṃgate! Bodhi Svāhā! 
</p>
<p>
Great refinement of wisdom, Prajñāpāramitā, Heart Sutra!
</p>

`,
}
