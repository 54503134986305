import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240212: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-12',
  filename: '2024-02-12-intentional-bodymind.mp3',
  key: '5980e228-7a31-4ad3-82a1-e6a0482368cd',
  published: '2024-02-16',
  title: 'Realizing the Intentional Bodymind',
}
