/**
 * @function formatIso8601Date
 * @description A function to format ISO 8601 dates.
 *
 * @param value - The input value to be formatted. Expected to be an
 *                ISO 8601 date string in the format 'YYYY-MM-DD'.
 * @param longDate - An optional flag specifying whether to use
 *                   a long ('full') date format. Defaults to 'false'.
 *
 * @returns A date string formatted based on the user's locale and the
 *          specified format option. If the input is not a valid date string,
 *          the function will return an empty string.
 * Example:
 * // Outputs e.g., 'August 24, 2022' for English language setting.
 * const formattedDate = formatIso8601Date('2022-08-24', true);
 */
export function formatIso8601Date(
  value: unknown,
  longDate = false,
): string {
  if (typeof value !== 'string') return ''
  if (value.length !== 10) return ''

  const year = +value.slice(0, 4)
  const month = +value.slice(5, 7)
  const day = +value.slice(8, 10)

  const intl = new Intl.DateTimeFormat(
    // @ts-ignore
    navigator.languages,
    longDate ? {dateStyle: 'full'} : undefined,
  )
  return intl.format(new Date(year, month - 1, day))
}

