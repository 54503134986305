import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240603: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-03',
  filename: '2024-06-03-avatar-gains-nothing.mp3',
  key: 'd8859da4-ef1d-42b1-941d-00a4b31dd529',
  published: '2024-06-15',
  title: 'The Avatar Gains Nothing',
}
