import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240122: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-22',
  filename: '2024-01-22-zazen.mp3',
  key: '5f909950-c02f-4f57-a8d5-6ddaae18327f',
  published: '2024-01-26',
  title: 'Zazen',
}
