import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231113: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-13',
  filename: '2023-11-13-6-refinements-meditation-general-overview.mp3',
  key: 'c928c26e-7410-4dce-84d9-ec1c18d39761',
  published: '2023-11-26',
  title: '6 Refinements: Meditation: General Overview',

  notes: `
<p>Text for the meditation:</p>
<p>All equally experience suffering and happiness, I should look after them as I do myself.</p>
<h5>Homework</h5>
<p>
This meditation practice will put the conscious mind to work. 
Think about a place 
(mine is a meadow at ten thousand feet elevation in the Rocky Mountains of Colorado) 
where you feel safe and calm. 
Let your imagination build the picture (broad meadow decorated with grasses and wildflowers, a light breeze carrying the scent of snow). 
Now, just sit with the image. 
Hold it in your mind. 
When it fades gently bring the bodymind back to that place. 
Meditate for fifteen minutes, starting over as many times as you need to.
</p>
`,
}
