import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231013: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-13',
  filename: '2023-10-13-superstition.mp3',
  key: 'ca145744-55a8-4d1c-99b2-4c68cbcb422a',
  published: '2023-12-07',
  title: 'Superstitions',
}
