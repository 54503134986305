import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240607: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-07',
  filename: '2024-06-07-suffering.mp3',
  key: '8a62ef19-4dd0-4602-ac87-84dbd58be6db',
  published: '2024-06-15',
  title: 'Suffering (Dukkha)',
}
