import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231222: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-22',
  filename: '2023-12-22-cookies.mp3',
  key: '0babcc7f-3838-4d4d-8420-ab0633c4e91e',
  published: '2023-12-30',
  title: 'Cookies',
}
