import React from 'react'

export function AudioNotes(props: { notes: string }) {
  const {notes} = props

  return (
    <>
      <div className="row text-light-emphasis bg-light-subtle border border-light-subtle rounded-3">
        <div className="col m-3">
          <div dangerouslySetInnerHTML={{__html: notes}}>
          </div>
        </div>
      </div>
    </>
  )
}
