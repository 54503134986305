import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231002: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-02',
  filename: '2023-10-02-courage.mp3',
  key: 'a8faeb4d-0741-4568-a341-306014d786dc',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Courage',
}
