import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240422: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-22',
  filename: '2024-04-22-intent.mp3',
  key: '39726442-d635-421a-b41e-813399ea9044',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Intent',
}
