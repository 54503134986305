import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230113: IAudioItem = {
  category: 'buddhist',
  date: '2023-01-13',
  filename: '2023-01-13-history-untouchable-constitution.mp3',
  key: 'aafba91a-5bd7-4d16-97d7-2f3665ec0a78',
  published: '2023-12-08',
  title: 'History: The Untouchable and the Constitution',
}
