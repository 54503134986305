import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231215: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-15',
  filename: '2023-12-15-samatha-ii.mp3',
  key: '8200b95c-d305-4648-a331-5ebb126c7a09',
  published: '2023-12-15',
  title: '6 Refinements: Meditation: Samatha II',

  notes: `
<h5>SAMATHA II HOMEWORK:</h5>
<p>
Assume a good meditation posture. Take three deep breaths with noisy out breaths. Then imagine an animal that you are afraid of. An animal that you can never see yourself interacting with. Let your conscious mind create the image, sound, smell, and feel. 
</p>

<p>
Keep that image in your mind’s view. When it fades or is replaced by another image, just return to the original image. Do this for ten minutes each day.
</p>

<p>
Contemplate the image you chose and why. Why did the image fade or get replaced? How did the image make you feel? Could you: Accept it? Accept how it feels? Keep your focus on it?
</p>
`
}
