import React from 'react'

import { AudioButtonList } from '../../common/audio-button-list'
import { buddhaCenterDatabaseService } from '../../lib/buddha-center/buddha-center-database.service'
import { LocationLink } from './location-link'

export function PreceptClassList2023() {
  return (
    <>
      <h2 className="display-2">Five Lay Precepts</h2>

      <h3>Framed Picture</h3>
      <p>
        This is a PDF of the five lay precepts on letter paper (8-1/2" x 11")
        with marks for putting it in a 8"x10" picture frame.
      </p>
      <p>
        This is was made by members of the
        {' '}<LocationLink location={'sangha-circle'}/>{' '}
        sangha.
        It's offered freely.
      </p>

      <a className="btn btn-outline-primary m-3"
         target="_blank"
         href="/assets/buddhist/precepts-marked-for-8inx10in-on-letter-paper.pdf">
        Download
      </a>

      <h3>2023 Lay Precept Classes</h3>
      <p>
        These are recordings of the lay precept training by Shi Wayne Hughes
        at the
        {' '}<LocationLink location={'buddha-center'}/>{' '}
        in SecondLife.
      </p>

      <p>
        Click a talk for details.
      </p>
      <AudioButtonList
        prefix={'lay-precept'}
        list={buddhaCenterDatabaseService.preceptClass2023}
      />
    </>
  )
}
