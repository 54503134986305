import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240105: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-05',
  filename: '2024-01-05-meditation-focus-journaling.mp3',
  key: 'be63aaee-1cbf-4d75-8685-245b67e42150',
  published: '2024-01-05',
  title: '6 Refinements: Meditation: Focus and Journaling',
}
