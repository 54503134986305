import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230605: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-05',
  filename: '2023-06-05-precepts-1.mp3',
  key: 'a39393ae-ce84-48c6-8891-eeadf57fd6e9',
  title: 'Lay Precept Training, Session 1: Introduction',

  notes: `
  <h5>
  Lay Precepts: Introduction
  </h5>

<p>Engaged Dharma Precepts (founded in Pragmatic Buddhist principles)</p>

<ol>
<li>I undertake the training of generosity; I will abstain from taking what is not given.</li>
<li>I undertake the training of moderation and contentment; I will abstain from sexual misconduct and the abuse of sensory pleasures.</li>
<li>I undertake the training of positive speech; I will abstain from false speech.</li>
<li>I undertake the training of life-affirming action; I will abstain from the cultivation of unwholesome habitual reactivities.</li>
<li>I undertake the training of loving-kindness; my intent is to abstain from killing sentient beings.  I will honor life.</li>
</ol>

<p>
The precepts are vows, pledges to bring needed direction and intention into how you interact with yourself, others and the universe.  
They are not commandments given by the Buddha though in Buddhist centric societies they are seen as norms one should strive to live by.
</p>

<p>
Taking precepts in Buddhism is vowing to engage an advanced level of moral discipline (P., sila) 
in all situations irregardless of whatever emotional impulse might arise.  
Sila brings into harmony how we imagine we can be, 
how we can act morally and ethically toward others, 
and how we come to fully realize the Four Ennobling Truths.  
Practitioners must learn and strive to follow the precepts in order to realize concentration (samadhi) and wisdom (prajna) as spontaneous ways of being.
</p>


<p>
Precepts can be a cause of guilt, anxiety and remorse when thoughts or actions arise that are antithetical to their intent.  Guilt and remorse may arise as a karmic consequence of unskillful action and these emotional reactions can act as a deterrent to future unskillful choices.  However, a practitioner must not indulge these emotions; they must be made to fall away once they have served their purpose.
</p>

<p>
It is much more effective to fail and to learn, than to act with moral ambiguity.
The intent of precept vows is that they guide the practitoner to act with moral discipline unbiased by personal perceptions, delusions and expectation in order to alleviate suffering and promote human flourishing.
</p>

<p>
There is a question that must arise when studying and practicing the precepts.  What if I mess up?  How will I know if I do?  First, there is no doubt you will mess up.  Second, in the beginning you may not recognize you've messed up.  You will, with the experience that comes with practice gradually develop the mindfulness and awareness that allows you to answer the questions.
</p>

<p>
HABITUAL REACTIVITIES are the dispositions, thoughts and actions we take, wholesome and unwholesome that seem “hard wired” to how we interact with the world.  They seem hard wired because that has become the comfortable way to react, to react based on emotion and a misunderstanding of how the universe works.
</p>

<p>
The precepts are not commandments meant to be followed with blind faith.  They, like the Eightfold Path are guides subject to experiential verification.
</p>

<p>
The precepts in a nutshell:  <strong>I WILL HONOR LIFE</strong>.
</p>

<h6> Homework </h6>

<p>
Write the 5 Lay Precepts on a piece of paper.  Once per day (at least) read them, out loud when it is appropriate.  Become familiar with them.   
</p>
  `,
}
