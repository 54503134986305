import React from 'react'
import { v4 } from 'uuid'

import { buddhaCenterDatabaseService } from '../../lib/buddha-center/buddha-center-database.service'
import { LimitedTalkList } from '../../common/limited-talk-list/limited-talk-list'
import { LimitedTalkListButton } from '../../common/limited-talk-list/limited-talk-list-button'

const FiveAllButtons: LimitedTalkListButton[] = [
  {count: 5, display: 'Show 5', uuid: v4()},
  {count: -1, display: 'Show All', uuid: v4()},
]

export function DharmaTalkList() {
  return (
    <>
      <h2 className="display-2">Dharma Talks</h2>
      <p>
        These are recordings of some dharma talks given by Shi Wayne Hughes
        and guest speakers.
      </p>

      <h3 className="display-3">2024</h3>
      <LimitedTalkList
        prefix={'Y2024-'}
        talks={buddhaCenterDatabaseService.talksByYear('2024')}
        buttons={FiveAllButtons}/>

      <h3 className="display-3">2023</h3>
      <LimitedTalkList
        prefix={'Y2023-'}
        talks={buddhaCenterDatabaseService.talksByYear('2023')}
        buttons={FiveAllButtons}/>

      <h3 className="display-3">2021</h3>
      <LimitedTalkList
        prefix={'Y2021-'}
        talks={buddhaCenterDatabaseService.talksByYear('2021')}
        buttons={FiveAllButtons}/>
    </>
  )
}
