import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240621: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-21',
  filename: '2024-06-21-humility.mp3',
  pdf: '2024-06-21-humility.pdf',
  key: '48c204f8-01c1-4ddc-a9fa-a4e601ec8c6f',
  published: '2024-06-22',
  title: 'Shantideva and Humility',
  speaker: 'Tvenkel Xuni Zuo',
  location: 'sangha-circle',
}
