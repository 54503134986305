import React from 'react'

export function SelfCarePage() {
  return (
    <>
      <h1 className="display-1">Self Care</h1>

      <h2 className="display-3">Physical Health</h2>
      <ul>
        <li>Sleep</li>
        <li>Good food</li>
        <li>Exercise</li>
      </ul>

      <h2 className="display-5">Bathroom</h2>
      <ul>
        <li>Shower</li>
        <li>Shaving; check if needed at all, on Saturday.</li>
        <li>Skin care</li>
        <li>Hair care</li>
        <li>Deodorant</li>
        <li>Vitamins</li>
        <li>Allergy Meds</li>
        <li>Teeth care</li>
        <li>Nail care</li>
        <li>Makeup if using today.</li>
      </ul>

      <h2 className="display-3">Mental Health</h2>
      <ul>
        <li>DBT Exercises</li>
        <li>Mindfulness; meditation</li>
        <li>Good book</li>
        <li>Writing</li>
      </ul>
    </>
  )
}


