import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231208: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-08',
  filename: '2023-12-08-social-not-self.mp3',
  key: 'b87c4499-aab6-4513-9836-1b8302131891',
  published: '2023-12-11',
  title: 'Social Not-Self',
}
