import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240108: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-08',
  filename: '2024-01-08-enlightenment-slow-and-sudden.mp3',
  key: '826ee48e-46b5-498c-b2ab-25ad82b1cdf9',
  published: '2024-01-08',
  title: 'Enlightenment: Slow and Sudden',
}
