import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240126: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-26',
  filename: '2024-01-26-meditation-short-topics.mp3',
  key: 'dc7933fb-34fc-4cac-9fea-3cc9e36107a4',
  published: '2024-01-26',
  title: 'Meditation: Short Topics',
}
