import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240322: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-22',
  filename: '2024-03-22-bodhisattva-vow.mp3',
  key: 'e9a8b0f0-9a9f-4398-9ee7-c9e0567fcbab',
  published: '2024-04-30',
  title: 'Bodhisattva Vow',
}
