import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230609: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-09',
  filename: '2023-06-09-precepts-2.mp3',
  key: 'b2cbecea-02ab-47e0-921b-2c1a3c7ac44e',
  title: 'Lay Precept Training, Session 2: Generosity',

  notes: `
  <h5>
 Lay Precepts: Generosity 
  </h5>

<p>
I UNDERTAKE THE TRAINING OF GENEROSITY.  
I WILL ABSTAIN FROM TAKING WHAT IS NOT GIVEN.
</p>

<p>
Generosity is a critical Buddhist practice that goes beyond 
the traditional precept of “I will not steal” to recognize that 
generosity is the antithesis of stealing, 
and that taking what is not given goes beyond the theft or 
misappropriation of material goods.
</p>

<p>
Generosity is one of the Six Perfections in Mahayana philosophy and practice.  
It, along with morals/ethics, 
acceptance/tolerance, 
vitality/energy, 
meditation and wisdom are the core thoughts and actions of a Bodhisattva-in-training.
</p>

<p>
The pragmatic question that any Buddhist practitioner should 
ask themselves is how can I be a cause of generosity, 
and accept with sincere gratitude generosity that I am the benefit of.
</p>

<p>
There is a practice of generosity that can get clouded over by giving; 
that is receiving.  
Sharing the Merit begins with 'Showing our gratitude . . .”.
</p>

<h6>
SHARING THE MERIT (recited after all EDIG sessions, public and private)
</h6>

<p>
Showing our gratitude and generosity, 
practicing the way of awareness which gives rise to benefits without limit, 
we vow to share these benefits of our practice, 
service, and gifts with all beings. 
Let us be reminded that a life of engagement and compassion is supremely important. 
Time swiftly passes by and opportunity is lost. 
Each of us should strive to become aware of our connectedness to others, 
and not squander the gift of realizing the wisdom of engaging the Dharma.
</p>

<p>
We show, we practice, we vow, we share, we strive, and we realize; 
all thoughts and actions directed toward developing a wholesome generosity of spirit.
</p>

<p>
I will abstain from taking what is not given.  
Abstaining from stealing is straightforward.  
Don't take it if it doesn't belong to you.  
Abstaining from taking what is not given is more nuanced. 
</p>

<p>
The foundation of training in generosity and the act of abstaining from 
taking what is not given is experienced in the thoughts and actions of gratitude.  
A practitioner gives in gratitude for the dharma.  
The receiver gives gratitude for the generosity.  
A practitioner that experiences gratitude for 
what they have will not experience the arising of greed or envy, 
and will not want what isn't theirs. 
</p>

<h6> Homework </h6>

<p>
Write the word GRATITUDE on a piece of paper.  
Fold it up and put it in your pocket or purse.  
Be mindful of moments when gratitude is evident, 
is appropriate, or is missing (from you or from others).  
In those moments take out the paper, unfold it, read the word out loud.  
Take a moment and contemplate. 
</p>
  `
}
