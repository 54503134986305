import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230918: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-18',
  filename: '2023-09-18-appropriate-energy-effort.mp3',
  key: '7cb17d86-da64-4c1e-af69-1b328738e032',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Appropriate Energy and Effort',
}
