import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240426: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-26',
  filename: '2024-04-26-effort.mp3',
  key: 'cc64fe87-3a45-4aff-ba67-d5e0e21f817a',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Effort',
  notes: `
<h5>Notes</h5>
<blockquote>
If they should rouse the strength of effort, 
even gnats, mosquitoes, bees, and worms will gain what is so hard to achieve 
&emdash; unexcelled enlightenment.
</blockquote>
<cite>Śāntideva</cite>
`
}
