import React from 'react'

import { formatIso8601Date } from '../lib/format-iso-8601-date'
import { IAudioItem } from '../lib/talks/audio-item.interface'

export function AudioButtonList(props: {
  list: IAudioItem[],
  prefix: string,
  showPublishedDate?: boolean
}) {
  const {
    list,
    prefix,
    showPublishedDate,
  } = props

  return (
    <>
      <div className="list-group">
        {list.map((x) => (
          <React.Fragment key={`abl-fragment-${prefix}${x.key}`}>
            <a
              className="list-group-item list-group-item-action list-group-item-dark"
              href={`/talks/${x.key}`}
            >
              <div className="row">
                <div className="col-lg-9 col-md-6 col-sm-12">
                  {x.title}
                </div>
                <div style={{fontSize: 'small'}}
                     className="col-lg-3 col-md-6 col-sm-12">
                  {formatIso8601Date(x.date, true)}
                </div>
              </div>
              {
                (x.published && showPublishedDate) ?
                  <div className="row">
                    <div style={{fontSize: 'small'}}
                         className="col">
                      Published on {formatIso8601Date(x.published)}
                    </div>
                  </div>
                  : null
              }
            </a>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

