import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240624: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-24',
  filename: '2024-06-24-merit.mp3',
  key: '133da739-d6f5-4bc1-92a9-1a55a45039df',
  published: '2024-07-10',
  title: 'Merit',
  location: 'sangha-circle',
}
