import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230331: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-31',
  filename: '2023-03-31-generosity-audit-2.mp3',
  key: '56b955e9-e3c5-47b1-bbcc-a70d32c3ce4c',
  published: '2023-12-15',
  title: 'Generosity Audit: Sangha Answers',
}
