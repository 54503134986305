import React from 'react'

import { appVersion } from '../../lib/app-version'

export function Footer() {
  return (
    <>
      <div
        className="m-3"
        style={{
          fontSize: '0.8rem',
          borderTop: '0.25px solid',
        }}>
        Version {appVersion()}
      </div>
    </>
  )
}
