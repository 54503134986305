import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230616: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-16',
  filename: '2023-06-16-precepts-4.mp3',
  key: 'b3b82b8b-4862-4535-ba54-0f45799d1d19',
  title: 'Lay Precept Training, Session 4: Positive Speech',

  notes: `
 <h5>Lay Precepts: Speech</h5>

<p>
I UNDERTAKE THE TRAINING OF POSITIVE SPEECH.  
I WILL ABSTAIN FROM FALSE SPEECH.
</p>

<p>
In the Abhaya Sutra (Majjhima Nikaya) the Buddha offers causal factors 
that should guide a practitioner's decision to speak or remain silent.  
Traditionally there are only two instances when it is appropriate to speak.  
When the words are factual, 
truth, 
beneficial but might not be welcomed by the listener, 
and when the words are factual, 
truth, 
beneficial and welcomed by the listener.  
It both cases there is the pragmatic intent that whether the words are welcome or not, 
there is value in the listener hearing them.  
It is inappropriate to speak if the words are not factual, 
not truth, 
have no benefit for the listener and, 
in cases other than speaking the truth, 
when the words cause emotional distress.  
The Buddha did not mention that a statement could be untrue and still be beneficial.  
</p>

<p>
Like all the dharma the factors that the Buddha offers are situational.  
The majority of the time you want to tell the truth whether it is accepted or not, 
and whether it is welcomed or not.  
However, there will occasionally be gray areas 
(the whole Middle Path thing) 
when the pragmatic choice is to make an untrue statement. 
</p>

<p>
In general telling falsehoods causes the arising of suffering however, 
there are uncommon situations when a lie can contribute to the alleviation of suffering.
</p>

<p>
CAN YOU THINK OF OTHER SITUATIONS WHEN LYING MIGHT BE BENEFICIAL
</p>

<p>
The training of positive speech begins with inward speech, 
with the language and attitude that the ego speaks with.
</p>

<p>
Speech is the only human activity that is both an aspect 
of the Eightfold Path and is one of the Buddhist vows that impact 
a person's moral and ethical character.  
Speech is how humans communicate, sometimes not well.  
The intent of this precept vow is for the practitioner to strive 
to communicate with others in the most useful and productive way, 
a way that leads to human flourishing (happiness, health and harmony).
</p>

<p>
False speech is much more than telling lies.
</p>

<p>
A “false” way to act is speaking in any manner that causes harm, 
that detracts from happiness, health and harmony.
</p>

<p>
There are moments in the sutras when the Buddha chooses 
to remain silent when asked specific metaphysical questions, 
questions whose only answer in that moment was I don't know.  
This is an example of the Buddha avoiding meaningless speech.  
Silence is as direct a way of “saying” I don't know as the spoken words themselves.
</p>

<p>
A pragmatic option to harsh speech that he called “direct speech”, 
or be direct, specific and non-punishing
</p>

<p>
Be mindful of how you interact with people in the real environment 
and to how you react to the speech of others.  
How you react to the speech of others is a sometimes overlooked aspect of a vow to engage in positive speech.
</p>

<p>
The antidote for the moral hindrances of lying, gossip, harsh speech, 
meaningless speech, and aggressive speech is loving-kindness.  
Loving-kindness is putting yourself in their place, 
with their feelings and experiencing them.  
It is extremely difficult to fail to treat others with loving-kindness 
when you accept their suffering as you would your own.    
</p>

<h6>Homework</h6>

<p>
Take a piece of paper (a 3x5 card works well) and write the words FALSE SPEECH.  
During the week ahead when you speak falsely to yourself take it out, 
unfold it, read it, fold it, put it away.  
This is your internal speech.  
How often do you engage in lying, gossiping, speaking harshly or aggressively, 
and yes, even meaninglessly speech to yourself?  
Practice mindfulness and engage in rigorous self-honesty. 
</p>
  
  `
}
