import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230317: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-17',
  filename: '2023-03-17-skillful-means.mp3',
  key: 'd15d6308-eb5c-4b39-8348-b7071ba13f1b',
  published: '2023-12-15',
  title: 'Skillful Means',
}
