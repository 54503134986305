import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230623: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-23',
  filename: '2023-06-23-precepts-6.mp3',
  key: 'e2320086-b610-446c-8989-3114d6db74ed',
  title: 'Lay Precept Training, Session 6: Honoring Life',

  notes: `
  <h5>Lay Precepts: Honor Life</h5>

<p>
I UNDERTAKE THE TRAINING OF LOVING-KINDNESS.  
MY INTENT IS TO ABSTAIN FROM KILLING SENTIENT BEINGS.  
I WILL HONOR LIFE.
</p>

<p>
There is the training of loving-kindness, 
of sincere empathy for the suffering of others and 
the intent to treat all beings with respect and compassion.  
The training is strengthened by an intentional practice to abstain from killing sentient beings.  
This practice isn't as simple as choosing not to kill and to be a vegan.  
Loving-kindness and harmlessness (ahimasa) must permeate all aspects of how you live.
</p>

<p>
Loving-kindness for living beings is clearly a wholesome action and 
there is room for loving-kindness directed toward non-beings.  
For example the planet Earth isn't a living being but it does suffer 
in clear and recognizable ways that can benefit from loving-kindness
</p>

<p>
The Buddha teaches in the Saraniya Sutra that there are conditions of practicing loving kindness.    
Through these practices arise compassion, 
respect for self and others, 
a sense of belonging to a broader tribe, 
renunciation of violence and hatred.
</p>

<p>
A mental act of good will arises when we tell a friend or loved one 
to have a nice day or to drive safely, 
or when we feel empathy for someone we don't know.  
A classic Buddhist mental act of good will is Metta prayer.  
</p>

<p>
May "all beings" be happy, healthy and whole. 
<br />
May they have love, warmth and affection. 
<br />
May they be protected from harm, and free from fear. 
<br />
May they be alive, engaged and joyful. 
<br />
May "all beings" enjoy inner peace and ease. 
<br />
May that peace expand into their world and throughout the entire universe. 
</p>

<p>
“My intent is to abstain from killing sentient beings.”  
This part of the precept is offered differently than 
the traditional precept of 'Do not kill' or some variation of that, 
though the intent is the same.
</p>

<p>
Reality check.  
How feasible is for you to not kill one sentient being as you go through an average day?  
If you think it is, how do you accomplish it?  
If you think it is not, why not?  
</p>

<p>
Have you ever killed a sentient being on purpose?  
If yes, why?  
If no, why?  
Have ever thought about, 
or expressed the intent to kill a sentient being on purpose?  
If yes, why?  
If no, why?  
(This is a tricky one because most people have, 
at one moment in their lives have said or thought, 
'I want to kill . . . '. 
But was that really an intent or just 
a vocalization of an unwholesome habitual reactivity like hatred, 
envy or greed?) 
They are both unwholesome dispositions. 
</p>

<p>
Have you ever killed a sentient being without intent to?  
If yes, what was your reaction or response?  
If no, you are deluding yourself.  
What is your reaction or response to that?  
You have unintentionally killed a sentient being.  How does that make you feel?
</p>

<p>
Intent is the crucial causal factor in this precept, 
traditional and contemporary. 
</p>

<p>
I will honor life.  
This is a Pragmatic Buddhist vow.  
It is four words that encompass not only the intent of the lay precepts 
but must the intent of every thought and action in Buddhist practice.  
I will honor life.
</p>

<p>
I honor all living things who gave their lives, 
and all beings whose efforts brought this food before me.  
May the strength and vitality acquired by eating this meal be used for the benefit of all living beings.
</p>

<p>
I honor all living things who contribute to my flourishing.  
May the goods, knowledge and experience I gain be used to benefit all beings.
</p>

<h6>Homework</h6>

<p>
I honor all living things who gave their lives, 
and all beings whose efforts brought this food before me.  
May the strength and vitality acquired by eating this meal 
be used for the benefit of all living beings.  
Write this on a piece of paper and keep it handy.  
Whenever you eat (meal or snack) recite the dharani.  
Be mindful of transformations in your worldview.
</p>

<p>
I will honor life.  
It is four words that encompass not only the intent of the lay precepts 
but must be the intent of every thought and action in Buddhist practice.  
I will honor life. 
</p>
  
  
  `
}
