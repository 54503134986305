import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230911: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-11',
  filename: '2023-09-11-energy.mp3',
  key: 'f8c29b96-aeba-4e62-9d22-05015a9c7122',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Energy',
}
