import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240412: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-12',
  filename: '2024-04-12-speech.mp3',
  key: 'fb874e42-3d7e-4dbc-98a0-07dca134f1f6',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Speech',
}
