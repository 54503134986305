import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240513: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-13',
  filename: '2024-05-13-cycling-through-samsara-3.mp3',
  key: '34b8b03f-c082-42d4-9a0f-ae503ca909b3',
  published: '2024-05-13',
  title: 'Cycling Through Samsara, Part III',
}
