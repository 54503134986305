import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { buddhaCenterDatabaseService } from '../lib/buddha-center/buddha-center-database.service'
import { BuddhistPage } from '../top-level/buddhist-page/buddhist-page'
import { ErrorPage } from '../top-level/error-page/error-page'
import { HomePage } from '../top-level/home-page/home-page'
import { RootPage } from '../top-level/root-page/root-page'
import { SelfCarePage } from '../top-level/self-care-page/self-care-page'
import { TalkDetailPage } from '../top-level/talks-page/talk-detail-page'
import { talkToRawTranscriptionPath } from '../lib/talk-to-raw-transcription-path'

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <RootPage/>,
      errorElement: <ErrorPage/>,
      children: [
        {
          path: '',
          element: <HomePage/>,
        },
        {
          path: 'buddhist',
          element: <BuddhistPage/>,
        },
        {
          path: 'sc',
          element: <SelfCarePage/>,
        },
        {
          path: 'talks/:talkId',
          element: <TalkDetailPage/>,
          loader: async ({params}) => {
            const talk = buddhaCenterDatabaseService.getTalk(params.talkId)
            const transcriptPath = talkToRawTranscriptionPath(talk)
            const response = await fetch(transcriptPath)
            const rawTranscription =
              Math.floor(response.status / 100) === 2
                ? await response.text()
                : null

            return {
              talk,
              path: transcriptPath,
              rawTranscription,
            }
          },
        },
      ],
    },
  ],
  {
    basename: process.env.REACT_APP_BASENAME,
  },
)
