import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230224: IAudioItem = {
  category: 'buddhist',
  date: '2023-02-24',
  filename: '2023-02-24-paramitas.mp3',
  key: 'e8320b55-d6a4-49aa-9964-eb4e69c532dd',
  published: '2023-12-15',
  title: 'Paramitas',
}
