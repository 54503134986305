import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230227: IAudioItem = {
  category: 'buddhist',
  date: '2023-02-27',
  filename: '2023-02-27-generosity.mp3',
  key: '93de2188-c44b-4146-9f9b-e0ac0b7cea0f',
  published: '2023-12-15',
  title: 'Generosity',
}
