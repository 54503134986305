import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240209: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-09',
  filename: '2024-02-09-wisdom-2.mp3',
  key: 'e4027d30-922d-401a-a4c2-4efca64df648',
  published: '2024-02-16',
  title: '6 Refinements: Wisdom II',
  notes: `
 <h5>Handout: Wisdom II</h5> 
 <p>
Short, pity statements about wisdom have value only as long as there is 
the realization that no one of them is the “whole” truth. 
The author Copthorne Macdonald, in Toward Wisdom: Finding Our Way to 
Inner Peace, Love, and Happiness offers some views. 
He emphasized that the statements can help us see wisdom more clearly, 
but not fully because none of them encompass all of wisdom. 
They are a good place to start an understanding of wise thoughts and actions, 
though. 
It is also a good place to start the practices that are wise to do and 
that develop wisdom.
 </p>
 <p>
 Seeing things clearly; seeing things as they are.
 </p>
 <p>
 This is both the path to, and an expression of wisdom. 
 Every moment that we strive to stay in the present moment and practice 
 ACCEPTANCE of the reality of each moment reveals wisdom. 
 This both a practice of, and consequence of APPROPRIATE VIEW.
 </p>
 <h6>Practice:</h6>
 <p>
 Get ready for some rigorous self-honesty. 
 What do you see as a positive character trait? 
 What do you see as a negative character trait?  
 Taking them one at a time, 
 look deeper into your bodymind. 
 Are you seeing yourself clearly? 
 </p>
 <p>
 Acting in prudent and effective ways.
 </p>
 <p>
 Pragmatic actions are wise actions because they are actions 
 appropriate to the situation. Generous, ethical, and accepting actions, 
 done with vitality and equanimity are the arising of wisdom, 
 and actions of the wise.
 </p>
 <h6>Practice:</h6>
 <p>
 Consider a decision you recently had to make. 
 Did you take the appropriate action for the situation, 
 or the action you felt most comfortable taking? 
 If the first is true, 
 then you made a wise decision; 
 if the second, you did not.
 </p>
 <p>
 Making decisions with the well-being of the whole in mind.
 </p>
 <p>
 In Engaged Dharma there is the ideal of taking corrective and 
 encompassing actions in all situations. 
 So, not only is it wise to be situational when faced with a decision, 
 it is also wise to strive for your “fix” to be encompassing and corrective. 
 The intended consequences of your actions should be to correct the 
 problem encompassing as broad a range of those affected as possible.
 </p>
 <h6>Practice:</h6>
 <p>
 Imagine. You are walking down a sidewalk. 
 You notice ahead a piece of broken sharp glass on the pavement. 
 What would be your action?  
 Selfish or selfless? 
 </p>
 <ul>
<li>Step over it and keep going.</li>
<li>Push it off the sidewalk with your foot.</li>
<li>Pick it up and put it in the first available trash can.</li>
 </ul>
 <p>
 How do you feel about your decision?
 </p>
 <p>
 Deeply understanding the human and cosmic situation, 
 mankind’s experience, and human nature.
 </p>
 <p>
 Let’s drop the “cosmic” and focus on the human situation. 
 Life-long learning done with an open-heart and open mind, 
 experientially verifying the reality of the 
 Three Characteristics of Existence and of the 
 Buddha-element within are wise endeavors.
 </p>
 <h6>Practice:</h6>
 <p>
 Choose a book about Buddhism that interests you. 
 A couple I recommend are: 
 Breakfast with Buddha by Roland Merullo, 
 and 
 Why I Am a Buddhist by Stephen Asma. 
 Read, or listen to them, or another book of your choice.
 </p>
 <p>
Consider the impact that suffering, impermanence, 
and the idea of not-self has had on you.
Think of a wholesome habitual reactivity that guides your thoughts and actions. 
It arises from your Buddha-element, that bit of a Buddha in each of us.
 </p>
 <p>
 Knowing when to act and when not to.
 </p>
 <p>
 This wisdom is reached through experience. 
 Knowing when to act begins with the Three Characteristics of Skillful Means: 
 preparation, permission, resources.
 </p>
 <p>
 This not only applies to when to act or not; it applies to where, how, and why. 
 </p>
 <h6>Practice:</h6>
 <p>
 Contemplate an action you have thought about taking. 
 Are you prepared? 
 Do you have permission? 
 Do you have the resources? 
 Should you act or not act?
 </p>
 <p>
 Being able to handle whatever arises with equanimity, 
 and to deal with such issues in a compassionate and encompassing way.
 </p>
 <p>
 Surprises happen. 
 Some good. 
 Some bad. 
 Not allowing wisdom to drive reactions to situations and responding appropriately. 
 All situations are responded to without judgement, 
 and the intent is to have compassionate and broad ranging consequences.
 </p>
 <h6>Practice:</h6>
 <p>
 
 Time for some insight. 
 Contemplate a moment when something unexpected happened good or bad. 
 Remember you are not there for the emotions; 
 you are there to learn about yourself. 
 Were you able to be calm? 
 Did you try to view both sides of the situation? 
 What role did emotions play in the action? 
 What were the consequences?
 </p>
 <p>
 Being able to anticipate potential problems and develop ways to deal with them.
 </p>
 <p>
 This does not call for fondling the future or trying to make predictions. 
 It does call for intuition developed from the knowledge that comes as 
 a consequence of asking and answering questions. 
 People considered wise are often credited with intuition. 
 </p>
 <h6>Practice:</h6>
 <p>
 Let’s redescribe anticipate as ‘be mindful of’. 
 Contemplate a moment when you were mindful of the arising of a problem. 
 How did you respond? What were the consequences?
 </p>
 <p>
 We are not born wise. 
 Wisdom arises from choices we make. 
 The choices we make are paths to wisdom. 
 We make better choices by asking questions of ourselves and 
 the people and world around us and listening deeply to the answers. 
 Better choices arise from wisdom. 
 Wisdom arise form better choices.
 </p>
 <p>
 A note about the practices. 
 At the end of each session I say “remember you are always practicing” 
 because there is a lot of practicing to do. 
 </p>
 <p>
 </p>
 <p>
 </p>
 <p>
 </p>
 <p>
 </p>
 <p>
 </p>
 
  
`
}
