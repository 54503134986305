import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240506: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-06',
  filename: '2024-05-06-cycling-through-samsara-1.mp3',
  key: 'c100fe14-fcc1-4ac6-94c2-f237dc1c0b71',
  published: '2024-05-06',
  title: 'Cycling Through Samsara, Part I',
  notes: `
<p>
The book mentioned in the talk is: Early Buddhist Discourses by John J. Holder.
</p>
`
}
