import { IAudioItem } from '../talks/audio-item.interface'

import { Talk20211231 } from './2021/talk-2021-12-31'
import { Talk20230102 } from './2023/talk-2023-01-02'
import { Talk20230106 } from './2023/talk-2023-01-06'
import { Talk20230109 } from './2023/talk-2023-01-09'
import { Talk20230113 } from './2023/talk-2023-01-13'
import { Talk20230116 } from './2023/talk-2023-01-16'
import { Talk20230121 } from './2023/talk-2023-01-21'
import { Talk20230123 } from './2023/talk-2023-01-23'
import { Talk20230127 } from './2023/talk-2023-01-27'
import { Talk20230130 } from './2023/talk-2023-01-30'
import { Talk20230203 } from './2023/talk-2023-02-03'
import { Talk20230206 } from './2023/talk-2023-02-06'
import { Talk20230213 } from './2023/talk-2023-02-13'
import { Talk20230217 } from './2023/talk-2023-02-17'
import { Talk20230220 } from './2023/talk-2023-02-20'
import { Talk20230224 } from './2023/talk-2023-02-24'
import { Talk20230227 } from './2023/talk-2023-02-27'
import { Talk20230303 } from './2023/talk-2023-03-03'
import { Talk20230310 } from './2023/talk-2023-03-10'
import { Talk20230313 } from './2023/talk-2023-03-13'
import { Talk20230317 } from './2023/talk-2023-03-17'
import { Talk20230320 } from './2023/talk-2023-03-20'
import { Talk20230324 } from './2023/talk-2023-03-24'
import { Talk20230327 } from './2023/talk-2023-03-27'
import { Talk20230331 } from './2023/talk-2023-03-31'
import { Talk20230602 } from './2023/talk-2023-06-02'
import { Talk20230605 } from './2023/talk-2023-06-05'
import { Talk20230609 } from './2023/talk-2023-06-09'
import { Talk20230612 } from './2023/talk-2023-06-12'
import { Talk20230616 } from './2023/talk-2023-06-16'
import { Talk20230619 } from './2023/talk-2023-06-19'
import { Talk20230623 } from './2023/talk-2023-06-23'
import { Talk20230626 } from './2023/talk-2023-06-26'
import { Talk20230630 } from './2023/talk-2023-06-30'
import { Talk20230731 } from './2023/talk-2023-07-31'
import { Talk20230901 } from './2023/talk-2023-09-01'
import { Talk20230911 } from './2023/talk-2023-09-11'
import { Talk20230915 } from './2023/talk-2023-09-15'
import { Talk20230918 } from './2023/talk-2023-09-18'
import { Talk20230922 } from './2023/talk-2023-09-22'
import { Talk20230925 } from './2023/talk-2023-09-25'
import { Talk20231002 } from './2023/talk-2023-10-02'
import { Talk20231013 } from './2023/talk-2023-10-13'
import { Talk20231016 } from './2023/talk-2023-10-16'
import { Talk20231020 } from './2023/talk-2023-10-20'
import { Talk20231023 } from './2023/talk-2023-10-23'
import { Talk20231027 } from './2023/talk-2023-10-27'
import { Talk20231103 } from './2023/talk-2023-11-03'
import { Talk20231106 } from './2023/talk-2023-11-06'
import { Talk20231110 } from './2023/talk-2023-11-10'
import { Talk20231113 } from './2023/talk-2023-11-13'
import { Talk20231117 } from './2023/talk-2023-11-17'
import { Talk20231127 } from './2023/talk-2023-11-27'
import { Talk20231204 } from './2023/talk-2023-12-04'
import { Talk20231208 } from './2023/talk-2023-12-08'
import { Talk20231211 } from './2023/talk-2023-12-11'
import { Talk20231215 } from './2023/talk-2023-12-15'
import { Talk20231218 } from './2023/talk-2023-12-18'
import { Talk20231222 } from './2023/talk-2023-12-22'
import { Talk20231229 } from './2023/talk-2023-12-29'
import { Talk20240105 } from './2024/talk-2024-01-05'
import { Talk20240108 } from './2024/talk-2024-01-08'
import { Talk20240112 } from './2024/talk-2024-01-12'
import { Talk20240115 } from './2024/talk-2024-01-15'
import { Talk20240119 } from './2024/talk-2024-01-19'
import { Talk20240122 } from './2024/talk-2024-01-22'
import { Talk20240126 } from './2024/talk-2024-01-26'
import { Talk20240129 } from './2024/talk-2024-01-29'
import { Talk20240202 } from './2024/talk-2024-02-02'
import { Talk20240209 } from './2024/talk-2024-02-09'
import { Talk20240212 } from './2024/talk-2024-02-12'
import { Talk20240216 } from './2024/talk-2024-02-16'
import { Talk20240219 } from './2024/talk-2024-02-19'
import { Talk20240223 } from './2024/talk-2024-02-23'
import { Talk20240226 } from './2024/talk-2024-02-26'
import { Talk20240301 } from './2024/talk-2024-03-01'
import { Talk20240304 } from './2024/talk-2024-03-04'
import { Talk20240318 } from './2024/talk-2024-03-18'
import { Talk20240322 } from './2024/talk-2024-03-22'
import { Talk20240325 } from './2024/talk-2024-03-25'
import { Talk20240329 } from './2024/talk-2024-03-29'
import { Talk20240401 } from './2024/talk-2024-04-01'
import { Talk20240412 } from './2024/talk-2024-04-12'
import { Talk20240415 } from './2024/talk-2024-04-15'
import { Talk20240419 } from './2024/talk-2024-04-19'
import { Talk20240422 } from './2024/talk-2024-04-22'
import { Talk20240426 } from './2024/talk-2024-04-26'
import { Talk20240429 } from './2024/talk-2024-04-29'
import { Talk20240503 } from './2024/talk-2024-05-03'
import { Talk20240506 } from './2024/talk-2024-05-06'
import { Talk20240510 } from './2024/talk-2024-05-10'
import { Talk20240513 } from './2024/talk-2024-05-13'
import { Talk20240524 } from './2024/talk-2024-05-24'
import { Talk20240531 } from './2024/talk-2024-05-31'
import { Talk20240603 } from './2024/talk-2024-06-03'
import { Talk20240607 } from './2024/talk-2024-06-07'
import { Talk20240610 } from './2024/talk-2024-06-10'
import { Talk20240614 } from './2024/talk-2024-06-14'
import { Talk20240617 } from './2024/talk-2024-06-17'
import { Talk20240621 } from './2024/talk-2024-06-21'
import { Talk20240624 } from './2024/talk-2024-06-24'
import { Talk20240628 } from './2024/talk-2024-06-28'
import { Talk20240701 } from './2024/talk-2024-07-01'
import { Talk20240712 } from './2024/talk-2024-07-12'

export const AllTalksTable:IAudioItem[] = [
  Talk20211231,
  Talk20230102,
  Talk20230106,
  Talk20230109,
  Talk20230113,
  Talk20230116,
  Talk20230121,
  Talk20230123,
  Talk20230127,
  Talk20230130,
  Talk20230203,
  Talk20230206,
  Talk20230213,
  Talk20230217,
  Talk20230220,
  Talk20230224,
  Talk20230227,
  Talk20230303,
  Talk20230310,
  Talk20230313,
  Talk20230317,
  Talk20230320,
  Talk20230324,
  Talk20230327,
  Talk20230331,
  Talk20230602,
  Talk20230605,
  Talk20230609,
  Talk20230612,
  Talk20230616,
  Talk20230619,
  Talk20230623,
  Talk20230626,
  Talk20230630,
  Talk20230731,
  Talk20230901,
  Talk20230911,
  Talk20230915,
  Talk20230918,
  Talk20230922,
  Talk20230925,
  Talk20231002,
  Talk20231013,
  Talk20231016,
  Talk20231020,
  Talk20231023,
  Talk20231027,
  Talk20231103,
  Talk20231106,
  Talk20231110,
  Talk20231113,
  Talk20231117,
  Talk20231127,
  Talk20231204,
  Talk20231208,
  Talk20231211,
  Talk20231215,
  Talk20231218,
  Talk20231222,
  Talk20231229,
  Talk20240105,
  Talk20240108,
  Talk20240112,
  Talk20240115,
  Talk20240119,
  Talk20240122,
  Talk20240126,
  Talk20240129,
  Talk20240202,
  Talk20240209,
  Talk20240212,
  Talk20240216,
  Talk20240219,
  Talk20240223,
  Talk20240226,
  Talk20240301,
  Talk20240304,
  Talk20240318,
  Talk20240322,
  Talk20240325,
  Talk20240329,
  Talk20240401,
  Talk20240412,
  Talk20240415,
  Talk20240419,
  Talk20240422,
  Talk20240426,
  Talk20240429,
  Talk20240503,
  Talk20240506,
  Talk20240510,
  Talk20240513,
  Talk20240524,
  Talk20240531,
  Talk20240603,
  Talk20240607,
  Talk20240610,
  Talk20240614,
  Talk20240617,
  Talk20240621,
  Talk20240624,
  Talk20240628,
  Talk20240701,
  Talk20240712,
]
