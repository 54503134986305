import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240112: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-12',
  filename: '2024-01-12-intro-to-vipassana.mp3',
  key: '57a455af-fdee-4736-b38d-71f025d1d28f',
  published: '2024-01-14',
  title: 'Introduction to Vipassana Meditation',
  notes: `
<h5>Notes</h5>
<ol>
<li>loving-kindness</li>
<li>sympathetic joy</li>
<li>compassion </li>
<li>equanimity</li>
</ol>
`
}
