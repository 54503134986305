import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230901: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-01',
  filename: '2023-09-01-energy-intro.mp3',
  key: '02de7ff1-d8d2-487c-9fc0-e7e4fe5136ca',
  published: '2023-12-15',
  title: '6 Refinements: Energy & Vitality: Introduction',

  notes: `
<h5>Vitality Audit</h5>
<p>
Contemplate each of the questions. Answer them with rigorous self-honesty. Answer them fully. Remember that no one else will see your answers unless you choose to make them public. This will be your view and experience of vitality/energy today. When this section of Dharma talks ends consider the questions again.  Answer them again.  How did your worldview change?
</p>

<ol>
<li>
What do you do to increase physical energy? 
</li>

<li>
What do you do to decrease physical energy? 
</li>

<li>
What activities drain your mental energy?
</li>

<li>
What activities increase your mental energy?
</li>

<li>
Rate your self-esteem on a scale of 1-10 with 1 being extremely low, 10 extremely high.
</li>

<li>
Why did you choose the Middle Path?
</li>

<li>
What do you desire?
</li>

<li>
How do you recharge your spiritual energy?
</li>

<li>
What is the most courageous thing you feel you have done?
</li>

<li>
“One should be addicted solely to the task that one is undertaking. One should be intoxicated by that task, insatiable, like someone hankering for the pleasure and the fruit of love-play.”  AGREE OR DISAGREE. WHY?
</li>
</ol>
  
`,
}
