import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231106: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-06',
  filename: '2023-11-06-6-refinements-breathing.mp3',
  key: '2bcb6d93-9b54-490f-affb-97a074afb85f',
  published: '2023-11-29',
  title: '6 Refinements: Meditation: Breathing',
  notes:`
<h4>HOMEWORK:</h4> 

<p>
For this meditation abdominal breathing will be your one-point focus.  
Thoughts will arise, emotions will arise, just experience them as they pass through.  
Your object of focus will be using the abdominal muscles to draw each in-breath (inhalation) and to expel each out-breath (exhalation).  
Between the in- and the out-, the out- and the in- is a space, a moment of emptiness that precedes the following moment.  
In that moment you begin the path to mindfulness of the bodymind
</p>
`
}
