import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240415: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-15',
  filename: '2024-04-15-livelihood.mp3',
  key: 'ed75ed1d-c587-4373-9d85-83c273de9e40',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Livelihood',
}
