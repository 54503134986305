import React from 'react'
import { talkToPdfPath } from '../../lib/talk-to-pdf-path'
import { IAudioItem } from '../../lib/talks/audio-item.interface'

export function AudioPdf(props: { audioItem: IAudioItem }) {
  const {audioItem} = props
  if (!audioItem?.pdf) return null

  const downloadPdf = async () => {
    try {
      const path = talkToPdfPath(audioItem)
      const response = await fetch(path)

      if (!response.ok) {
        throw new Error('HTTP error ' + response.status)
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = audioItem.pdf
      link.click()
    } catch (error) {
      console.error('An error occurred while downloading the PDF file', error)
    }
  }

  return (
    <>
      <div className="row">
        <button className="btn btn-primary" onClick={downloadPdf}>
          Download PDF
        </button>
      </div>
    </>
  )
}
