import { IAudioItem } from './talks/audio-item.interface'

export function talkToPdfPath(value: IAudioItem): string | null {
  const {category, pdf} = value
  if (!pdf) return null

  return category === 'buddhist'
    ? `/assets/buddhist/shi-wayne/${pdf}`
    : `/assets/dad/${pdf}`
}
