import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240301: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-01',
  filename: '2024-03-01-6-refinements-wrapup.mp3',
  key: 'cd6f0617-f660-453c-b771-1522aa4b03a8',
  published: '2024-03-07',
  title: '6 Refinements: Wrap-Up',
}
