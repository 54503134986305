import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230626: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-26',
  filename: '2023-06-26-precepts-7.mp3',
  key: 'cf7453c8-2b0b-4210-bc0f-39df71b60ebe',
  title: 'Lay Precept Training, Session 7: Closing Session',

  notes: `
 <h5>Lay Precepts: Closing Session</h5>

<p>
I undertake the training of generosity.  
I will abstain from taking what is not given.
</p>

<p>
I undertake the training of moderation and contentment.  
I will abstain from sexual misconduct and the abuse of sensory pleasures.
</p>

<p>
I undertake the training of positive speech.  
I will abstain from false speech.
</p>

<p>
I undertake the training of life-affirming action.  
I will abstain from the cultivation of unwholesome habitual reactivities.
</p>

<p>
I undertake the training of loving-kindness.  
My intent is to abstain from killing sentient beings.  
I will honor life.
</p>

<p>
Dalai Lama said, 
“No one should suppose it could ever be possible to devise a set of rules or 
laws to provide us with the answer to every ethical dilemma, 
even if we were to accept religion as the basis of morality.  
Such a formulaic approach could never hope to capture the richness and diversity of human experience.”  
He goes on to say, 
“there are bound to be situations when any course of action would appear to involve breaking a precept.  
Under such circumstances, 
we must use our intelligence (and wisdom) 
to judge which course of action will be least harmful in the long run.” 
</p>

<p>
In Chan precept-taking ceremonies it is incumbent on the practitioner 
to not take a precept if they feel they cannot live up to it.  
This is because of the weighty value that the Chan tradition puts on each precept vow.
</p>

<p>

There is a more pragmatic approach using the ideal of the “silent precept”.  
The Buddha teaches that the learning the dharma is a gradual process, 
a process that is actually strengthened by that pace.  
The Five Lay Precepts taken all at once can seem overwhelming, 
requiring some major changes to your life.  
So consider this, along with I undertake the training of loving-kindness.  
My intent is to abstain from killing sentient beings.  
I will honor life, 
choose another precept such as I undertake the training of positive speech.  
I will abstain from false speech.  
Let the other three precepts be silent . . . for now.  
Focus on the two precept vows for a time, 
whatever time it takes, 
and make the effort to integrate the intent of each one into how you live your life.  
This doesn't mean to forget the other three.  
Engage them when they come to mind.  
Over the course of time, 
as your mindfulness develops you'll come to the realization 
that you have been gradually getting experience in the practice of all five precepts.  
It is causal conditioning and impermanence at work. 
</p>

<p>
Keeping any of the precepts is seen as giving a gift to the world.
</p>

<p>
What happens if a precept is broken?  
This a question that relates to rules, 
not so much to precepts or vows.  
What happens is up to you.  
There is no one watching.  
</p>

<p>
There is a difference between taking actions outside the intent of a precept 
(such as telling a small falsehood in order to minimize suffering) 
and the out-and-out breaking of a precept 
(engaging in a slave/master relationship in Second Life).  
The first should come as a result of loving-kindness, 
the second arises from the ego, 
from attachment to a particular unwholesome habitual reactivity.
</p>

<p>
So, you broke a precept . . . now what?  
Do better the next time.  
How do you do that?  
That's where the work begins.  
Don't get mired down with guilt or shame.  
Instead look to your bodymind and discern, 
using rigorous self-honesty and an appropriate view of the situation 
what causal factors influenced your choice of thought or action.  
Then, and this may sound too simple, 
strive to do better in similar situations in the future.
</p>

<h6> Lay Precept Ceremony and Certificate </h6>

<p>
Editor's note:
The notecard this was taken from was from a previous year's precept class.
These dates were thus incorrect for this year.
</p>

<p>
On Nov 5th and 7th at the usually scheduled time there will be a Lay Precept Ceremony 
for those who have earned their dharma name by attending all sessions and participating in discussions.  
They will get an IM inviting them to the ceremony.  
All others are invited to attend and observe.
</p>

<p>
There are some who missed a few sessions.  
Make-up sessions will be held on Oct 29th and 31st.  
These sessions will be condensed lectures on each of the precepts.  
Expect the make-up class to be between and hour and a half to two hours in length.
</p>

<p>
Dharma names will be revealed at the ceremony and will appear on the certificate.  
The certificate will be given out as an “object” in SL.  
Any participant can receive a hard copy, 
signed by the teacher by sending their mailing address to v.wayne.hughes@gmail.com.   
</p>
  
  
  `
}
