import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240524: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-24',
  filename: '2024-05-24-5-strengths-of-practice.mp3',
  key: '2d3afd57-b4c3-44b4-872e-3f827cb2fcba',
  published: '2024-06-15',
  title: 'Five Strengths of Practice',
}
