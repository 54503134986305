import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231229: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-29',
  filename: '2023-12-29-resolutions-vs-vows.mp3',
  key: '36030fb8-31bc-4dcf-bb4b-452256f466c9',
  published: '2023-12-30',
  title: 'Resolutions vs Vows (missing first part of the talk)',

  notes: `
<h5>HOMEWORK:</h5>
<p>
What is a vow that you are more certain to honor? 
Contemplate what vow that is and take it. 
Take it for yourself and honor it for yourself. 
Vow to gently return to the intent of the chosen vow 
whenever you stray away or make mistakes.
</p>

<p>
Here are some of the easier vows to start with: 
<ul>
<li>I will honor life. </li>
<li>Do good for others. </li>
<li>I go for refuge to the sangha. </li>
<li>I will abstain from harsh speech. </li>
<li>I will listen more, talk less.</li>
</ul>
</p>
`
}
