import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230612: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-12',
  filename: '2023-06-12-precepts-3.mp3',
  key: '2d5a5312-9021-4184-bacb-b581fe95d0c0',
  title: 'Lay Precept Training, Session 3: Moderation and Contentment',

  notes: `
  <h5>Lay Precepts: Moderation</h5>

<p>
I UNDERTAKE THE TRAINING OF MODERATION AND CONTENTMENT.  
I WILL ABSTAIN FROM SEXUAL MISCONDUCT AND THE ABUSE OF SENSORY PLEASURES.
</p>

<p>
 The Buddha teaches that with  mindfulness and right view 
 you can avoid the two extremes of gluttony and deprivation with effort and commitment.  
 To be on the Noble Path means to practice moderation in all things, 
 to place yourself firmly on the Middle Path.  
 How can you undertake the training of moderation?
</p>

<p>
Training in moderation requires the development of 
a deep mindfulness of the differences between want and need. 
</p>

<p>
Continuous real happiness, 
the type of happiness that is most sought after by human beings, 
the highly elevated emotion, 
requires the neurotransmitter dopamine to be produced and 
the body just isn't capable of doing that continuously, 
and dopamine highs responsible for infatuation and 
extreme self-confidence pose dangers when changes to these conditions occur.  
Contentment is a sustainable state of being, 
sustainable whether we are happy or sad.  
It is more pragmatic to take a precept vow to undertake the training of contentment. 
</p>

<p>
The challenge for some is in how to recognize sexual misconduct.  
The Buddha offered tools, Buddhist tools to guide those on the Noble Path.  
The Three Pure Precepts is a guide to the realization of sexual misconduct.  
Do no harm.  
Do only good.  
Do good for others.  
The sexual act must not cause harm intellectually or physically.  
There must be intent for the sexual act to be beneficial to all parties.  
There must be constant mindfulness that every thought and action has a consequence in order to fully realize the practice of this precept.
</p>

<p>
It isn't the label that determines misconduct, 
it is the intent and action that does. 
</p>

<p>
It is the responsibility of the practitioner to engage 
their rigorous self-honesty and determine which sensory pleasures are 
accepted to be transient phenomena, and which are the cause of craving and attachment.
</p>

<h6>Homework</h6>
<p>
Fold a piece of paper in half and at the top on the left side write the word WANT, 
on the right the word NEED.  
You will experience wants and needs during the upcoming week.  
Before the next session have written on the left side your BIG WANT of the week, 
and on the right your BIG NEED.  
This will be a practice of mindfulness and of rigorous self-honesty.  
</p>
  `
}
