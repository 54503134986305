import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240419: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-19',
  filename: '2024-04-19-action.mp3',
  key: '24f0186a-122b-49cd-a4d2-03579cd4d279',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Action',
}
