import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230206: IAudioItem = {
  category: 'buddhist',
  date: '2023-02-06',
  filename: '2023-02-06-right-view-anuggahita-sutta.mp3',
  key: '676969bc-e0ad-428f-bbf5-1d233b2d42de',
  published: '2023-12-08',
  title: 'Right View: Anuggahita Sutta',
}
