import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230327: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-27',
  filename: '2023-03-27-generosity-audit.mp3',
  key: '4e10ec44-1d7b-4c39-860a-3f981d3dd28e',
  published: '2023-12-15',
  title: 'Generosity Audit and Discussion',
}
