import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240202: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-02',
  filename: '2024-02-02-wisdom.mp3',
  key: '4ebd6dfe-b2b9-4e42-862f-67d23fb8aff3',
  published: '2024-02-16',
  title: '6 Refinements: Wisdom I',
}
