/**
 * Sometimes empty strings represent no data,
 * and it's easier to just force them to null
 * rather than do a dance around empty strings being special case.
 *
 * Force "empty" strings (and non-strings such as undefined) to null.
 *
 * @param value - value to force to a string or null
 */
export function toStringOrNull(value: unknown): string | null {
  if (typeof value !== 'string') return null;

  return value.trim() !== '' ? value : null;
}
