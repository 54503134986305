import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240129: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-29',
  filename: '2024-01-29-meditation-in-action-plus-puja.mp3',
  key: '3ebde435-0e91-4a4e-afcc-da1ed13849f2',
  published: '2024-01-29',
  title: 'Meditation in Action // Puja for the Release of Compassionate Energy',
}
