import { IAudioItem } from './audio-item.interface'
import { types } from 'sass'
import Error = types.Error

export class TalksService {
  protected talks = new Map<string, IAudioItem>()

  constructor(database: IAudioItem[]) {
    let errors = 0
    database.forEach(x => {
      if (this.talks.has(x.key)) {
        errors++
        console.error(
          'Duplicate key found: ',
          this.talks.get(x.key),
          x,
        )
      }

      this.talks.set(x.key, x)
    })

    if (errors > 0)
      alert('Duplicate keys exist.')
  }

  protected fromIndex(index: string[]): IAudioItem[] {
    return index.map(x => this.talks.get(x))
  }

  public getAllTalks(): IAudioItem[] {
    return Array.from(this.talks.values())
  }

  public getTalk(talkId: string): IAudioItem {
    const talk = this.talks.get(talkId)
    if (!talk) throw new Error('Talk not found.')
    return talk
  }

  public talksByYear(year: string): IAudioItem[] {
    return this.getAllTalks()
      .filter(x => x.date.substring(0, 4) === year)
      .sort((a, b) => a.date.localeCompare(b.date))
  }
}
