import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240503: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-03',
  filename: '2024-05-03-concentration.mp3',
  key: '31923caa-9a42-4196-a175-2d0d7dd8399b',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Concentration',
  notes: `
<h5>Dharani for Concentration Off the Cushion</h5>
<p>
Be mindful of the task.
</p>

<p>
Whenever distracted by feelings, thoughts, or sounds say,
"Feeling. Feeling. Feeling."
</p>

<p>
Return to the task.
</p>

<p>
"Thinking. Thinking. Thinking."
</p>

<p>
Return to the task.
</p>

<p>
"Sound. Sound. Sound."
</p>

<p>
Return to the task.
</p>

<p>
Or any method proven to keep the mind focused and
bring it back gently when it strays.
</p>
`
}
