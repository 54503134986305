import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240216: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-16',
  filename: '2024-02-16-three-characteristics.mp3',
  key: '6c5336a1-e9ca-419a-a29c-4efbc03ed838',
  published: '2024-02-16',
  title: 'The Three Characteristics of Existence',
}
