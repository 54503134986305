import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230915: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-15',
  filename: '2023-09-15-energy-2.mp3',
  key: '17baba81-8459-4837-8943-e085d0f24b16',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Energy (continued)',
}
