import { Talk20230605 } from './2023/talk-2023-06-05'
import { Talk20230609 } from './2023/talk-2023-06-09'
import { Talk20230612 } from './2023/talk-2023-06-12'
import { Talk20230616 } from './2023/talk-2023-06-16'
import { Talk20230619 } from './2023/talk-2023-06-19'
import { Talk20230623 } from './2023/talk-2023-06-23'
import { Talk20230626 } from './2023/talk-2023-06-26'

export const PreceptClass2023Index: string[] = [
  Talk20230605.key,
  Talk20230609.key,
  Talk20230612.key,
  Talk20230616.key,
  Talk20230619.key,
  Talk20230623.key,
  Talk20230626.key,
]
