import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231211: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-11',
  filename: '2023-12-11-silence.mp3',
  key: 'f4e9dc5a-f69b-4707-a201-93b1a4b87ee6',
  published: '2023-12-11',
  title: 'Silence',
}
