import React from 'react'
import { useLoaderData } from 'react-router-dom'

import { AudioNotes } from '../../common/audio-notes/audio-notes'
import { AudioPdf } from '../../common/audio-notes/audio-pdf'
import { AudioPlayer } from '../../common/audio-player/audio-player'
import { AudioRawText } from '../../common/audio-notes/audio-raw-text'
import { formatIso8601Date } from '../../lib/format-iso-8601-date'
import { LocationLink } from '../buddhist-page/location-link'
import { talkToMp3Path } from '../../lib/talk-to-mp3-path'
import { talkToPdfPath } from '../../lib/talk-to-pdf-path'
import { toStringOrNull } from '../../common/to-string-or-null'
import { IAudioItem } from '../../lib/talks/audio-item.interface'

export function TalkDetailPage() {
  const data = useLoaderData() as any
  const {talk} = data
  const rawTranscription = toStringOrNull(data.rawTranscription)
  // console.log('rawTranscription', rawTranscription)

  const {
    category,
    date,
    notes,
    published,
    title,
    speaker,
    location,
  } = talk as IAudioItem

  const source = talkToMp3Path(talk)
  const pdf = talkToPdfPath(talk)

  const home = category === 'buddhist'
    ? '/buddhist'
    : '/dad'

  const talkGivenBy = speaker ?? 'Shi Wayne Hughes'

  const whichLocation = location ?? 'buddha-center'

  return (
    <>
      <div className="row">
        <div className="col-12">

          {
            (notes || rawTranscription) ?
              <div className="d-grid gap-2 m-3">
                <a href={home} className="btn btn-outline-primary">
                  Back
                </a>
              </div>
              :
              null
          }

          <div className="display-1"> {title} </div>
          {
            category === 'buddhist' ? (
              <p>
                This is a dharma talk given at the
                {' '}<LocationLink location={whichLocation}/>{' '}
                in SecondLife on {formatIso8601Date(date)}{' '}
                by {talkGivenBy}.
              </p>
            ) : null
          }
          <AudioPlayer source={source}/>

          {pdf ? <AudioPdf audioItem={talk}/> : null}

          {notes ? <AudioNotes notes={notes}/> : null}

          <AudioRawText rawText={rawTranscription}/>

          {published ? (
            <p>
              This talk was published on {formatIso8601Date(published)}.
            </p>
          ) : null}

          <div className="d-grid gap-2 m-3">
            <a href={home} className="btn btn-outline-primary">
              Back
            </a>
          </div>

        </div>
      </div>
    </>
  )
}
