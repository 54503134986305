import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230925: IAudioItem = {
  category: 'buddhist',
  date: '2023-09-25',
  filename: '2023-09-25-6-refinement-faith.mp3',
  key: '9e2c8b73-c002-4dd0-a908-ff8722596092',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Faith',
}
