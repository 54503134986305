import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231110: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-10',
  filename: '2023-11-10-learn-experience.mp3',
  key: 'fe5a852d-6df8-46cd-869d-0c92a68ca397',
  published: '2023-11-29',
  title: '6 Refinements: Meditation: Learning through experience',
  notes: `
<h3>Handout</h3>


<h4> Song of Meditation </h4>

<p>
<small class="text-body-secondary">
Hakuin Ekaku <br />
(1686-1786)
</small>
</p>


<p>
All living beings are originally Buddhas. <br />
Just like water and ice: Without water there is no ice, and outside living beings there is no Buddha.
</p>

<p>
Not knowing how near it is, people seek it outside themselves — what a pity!
</p>

<p>

Like someone in the middle of water crying out in thirst, or the child of a rich man, wandering around like a beggar, we are bound to the six worlds because we are lost in the darkness of ignorance.
</p>

<p>
Following dark path after dark path, <br />
when shall we escape birth and death?
</p>

<p>
The Zen meditation of Mahayana Buddhism is beyond all words of praise. <br />
The virtues of charity, morality, invoking the Buddha, repentance, training, and all other worthy actions have their source in meditation.
</p>

<p>
Even those who sit in Zazen only once, will destroy evil karma; how then can there be false paths?
</p>

<p>
The Pure Land is now very close. <br />
Listen with reverence to this teaching; <br />
praise, embrace it, and you will find merit. <br />
Better yet, look within and find self-nature beyond the self, and you will transcend words and explanations.
</p>

<p>
When you open the gate of cause and effect, <br />
You will discover a path beyond duality or multiplicity.
</p>

<p>
When you abide in the form that is no form, <br />
whether going or returning, you will always be at home.
</p>

<p>
When you take thought as non-thought, <br />
You will sing and dance to the music of Buddhist truth.
</p>

<p>
Boundless as the sky, radiant as the moon, is the fourfold wisdom. At this moment, what do you lack?
</p>

<p>
Nirvana is right in front of you. <br />
This very place is the Lotus Land. This body is the body of Buddha.
</p>


<h3>Homework</h3>

<p>
Here is a notecard with the Heart Sutra. 
For the next three days, twice a day, READ IT ALOUD. 
Pronunciation doesn’t matter, rhythm doesn’t matter. 
Volume doesn’t matter. 
Understanding doesn’t matter. 
Experiencing the Heart Sutra matters. 
</p>


<h5>HEART SUTRA  (Engaged Dharma Insight Group)</h5>

<p>
Avalokitesvara, Bodhisattva of compassion, observing deeply the refinement of wisdom, Prajnaparamita, clearly saw the emptiness of personality, thus enduring adversity and pain.  
</p>
<p>
O, Saripurtra, form is no other than emptiness, emptiness no other than form; 
form is exactly emptiness, emptiness exactly form, the same is true of feeling, perception, mental formations and consciousness.  O, Saripurtra, all dharmas are forms of emptiness, not born, not destroyed; 
not tainted, not pure, not increasing, not decreasing, and so in emptiness there is no form, no feeling, no perception, no mental formations, no consciousness; 
no eyes, no ears, no nose, no tongue, no body, no mind; 
no color, no sound, so smell, no taste, no touch, no thought, no realm of sight and so forth until no realm of consciousness, no ignorance, no end to ignorance and so forth until no old age and death, and no end to old age and death, no suffering, no desire, no cessation, no path, no wisdom, no attainment.  
</p>
<p>
And so the Bodhisattva relies on the Prajnaparamita with no hindrance in the mind, no hindrance, therefore no fear, far beyond deluded thoughts, this is Nirvana.  All past, present, and future Buddhas rely on the refinement of wisdom and thus attain the cultivated enlightenment.  Therefore, know that the Prajnaparamita is the interdependent mantra, the interconnected mantra, the mantra of world making the mantra which relieves all suffering, so proclaim the Prajnaparamita mantra, proclaim the mantra and say: Gate! Gate! Paragate! Parasamgate! Bodhi Sva Ha!   Great refinement of wisdom, Prajnaparamita, Heart Sutra!
</p>

`
}
