import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240429: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-29',
  filename: '2024-04-29-mindfulness.mp3',
  key: 'be0436b9-0674-4773-964c-f855f59c20fe',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate Mindfulness',
}
