import React from 'react'

export function AudioPlayer(props: {
  source: string,
}) {
  const {source} = props

  return (
    <>
      <div
        style={{
          margin: '8px 12px',
          // width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
        }}
      >

        <audio
          src={source}
          controls
          style={{width: '100%'}}
        >
          <source src={source}/>
        </audio>

        <a
          download={source}
          href={source}
          style={{
            paddingLeft: '0.5rem',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <i className="bi bi-download"></i>
        </a>
      </div>
    </>
  )
}
