import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240329: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-29',
  filename: '2024-03-29-8-fold-path-overview.mp3',
  key: '80182bb5-40c8-4180-bd28-a44f1972bd67',
  published: '2024-05-04',
  title: '8-Fold Path: Overview',
  notes: `
<h5>Note</h5>
<a href="https://www.dhammatalks.org/suttas/SN/SN45_8.html" target="_blank">
Magga-Vibhaṅga Sutta (SN45:8) 
</a>
`
}
