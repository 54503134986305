import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240304: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-04',
  filename: '2024-03-04-rituals.mp3',
  key: '90d28032-1f42-428e-bc30-47ae729c0b5a',
  published: '2024-03-04',
  title: 'Rituals',
}
