import React from 'react'
import { v4 } from 'uuid'

import { buddhaCenterDatabaseService } from '../../lib/buddha-center/buddha-center-database.service'
import { LimitedTalkList } from '../../common/limited-talk-list/limited-talk-list'
import { LimitedTalkListButton } from '../../common/limited-talk-list/limited-talk-list-button'

const RecentButtons: LimitedTalkListButton[] = [
  {count: 5, display: 'Show 5', uuid: v4()},
  {count: 10, display: 'Show 10', uuid: v4()},
  {count: 20, display: 'Show 20', uuid: v4()},
  {count: undefined, display: 'Show All', uuid: v4()},
]

export function RecentlyAddedDharmaTalks() {
  return (
    <>
      <h2 className="display-2">Recently Added</h2>
      <p>
        In the big list of talks,
        it's hard to know what's new.
        These are the most recent uploads.
      </p>

      <LimitedTalkList
        prefix={'recent-'}
        talks={buddhaCenterDatabaseService.recentPublications}
        buttons={RecentButtons}
        showPublishedDate={true}
      />
    </>
  )
}

