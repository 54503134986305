import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240115: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-15',
  filename: '2024-01-15-vipassana-meditation.mp3',
  key: 'b8225b09-5602-4d4a-aefa-cc66ef708d39',
  published: '2024-01-15',
  title: 'Vipassana Meditation',
  notes: `
<h5>Note:</h5>
<p>
The beginning minutes of this talk —
the opening ritual of intent and opening words —
is missing.
Just before the audio starts,
Shi Wayne said the reading is from the Dhammacakkappavattana Sutta
(The First Turning Of The Dharma Wheel).
</p>
`
}
