import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230619: IAudioItem = {
  category: 'buddhist',
  date: '2023-06-19',
  filename: '2023-06-19-precepts-5.mp3',
  key: '8313fb75-7a91-43d1-8807-00994f1a94fd',
  title: 'Lay Precept Training, Session 5: Life-Affirming Action',

  notes: `
  <h5>Lay Precepts: Life-Affirming Action</h5>
  
  <p>
 I UNDERTAKE THE TRAINING OF LIFE-AFFIRMING ACTION.  
 I WILL ABSTAIN FROM THE CULTIVATION OF UNWHOLESOME HABITUAL REACTIVITIES.
  </p>

<p>
To undertake the training in life-affirming action is 
to make a continuously mindful effort to rid oneself of 
the unwholesome and engage in wholesome activities that promote human flourishing, 
your's and other's. 
</p>

<p>
Life-affirming actions contribute to human flourishing and 
the Buddhist goal of liberation for all sentient beings.
</p>

<p>
This training vow will require you to develop and fully realize 
a broad and compassionate worldview in order to appropriately engage it.  
Broad because life, from birth to death, has broad potential.  
Compassionate because we affirm the value of life in moments of generosity, 
acceptance and loving-kindness.  
Affirming because living a noble life in each thought and action is certain to benefit all beings.
</p>

<p>
A guide to life-affirming action is found in the Eightfold Path, 
the path of appropriate livelihood.
</p>

<p>
The Six Perfections in Mahayana are fingers pointing to the “moon of affirmation”.  
Acting intentionally with morality/ethics, 
acceptance/tolerance, 
generosity of spirit, 
vitality/energy, 
meditative state of being, 
and wisdom is a path of affirmation. 
</p>

<p>
You can think of others ways to affirm life through your thoughts and actions.  
Use your imagination coupled with rigorous self-honesty to recognize your wholesome and unwholesome habitual reactivities.
</p>

<p>
Practicing the abstention from cultivating unwholesome habitual reactivities 
requires a deep level of mindfulness that realizes weak areas of character and 
that there is the potential to let them fall away to replaced by strength of character.  
It also requires rigorous self-honesty to admit attraction and 
sometimes attachment to thoughts and actions that the consciousness may recognize have, 
and have had negative consequences when engaged in. 
</p>

<p>
Each time you set aside greed in favor of generosity, 
hatred in favor of loving-kindness, 
and laziness with vitality (for a few examples) 
you are undertaking the training of life-affirming action.  
Like each of the lay precepts the training and the abstaining are interconnected and interdependent.  
This is in the manner of all phenomena in the universe.
</p>

<h6>Homework</h6>

<p>
Get another piece of paper.  
Across the top of the paper write, in capital letters the word HATE.  
Under that make four columns and label them:  Person – Place – Thing – Idea.
During the next week be mindful and aware when the word HATE is used.  
You must do this for your internal exclamations like “I hate . . . !”, 
and for those moments when the word is used by someone else.  
Use slash marks to keep track in each column.  
Take this a step further and mark which were internal and which external. 
</p>
  
  
  `
}
