import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240401: IAudioItem = {
  category: 'buddhist',
  date: '2024-04-01',
  filename: '2024-04-01-view.mp3',
  key: '0fe390de-7ef1-412e-aac4-471659bbf9eb',
  published: '2024-05-04',
  title: '8-Fold Path: Appropriate View',
}
