import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231027: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-27',
  filename: '2023-10-27-6-refinements-meditation-introduction.mp3',
  key: 'e0a6d958-5d29-491f-b135-d49be6582d3b',
  published: '2023-11-29',
  title: '6 Refinements: Meditation: Introduction',
}
