import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230313: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-13',
  filename: '2023-03-13-nagarjuna-benefit-generosity.mp3',
  key: '4224bac7-3f78-46b2-82ec-d8019a364946',
  published: '2023-12-15',
  title: 'Nagarjuna: Benefits of Generosity',
}
