import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231016: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-16',
  filename: '2023-10-16-enlightenment.mp3',
  key: '9f59402e-e65d-4086-84a7-ba1aa4b7edbf',
  published: '2023-12-07',
  title: '6 Refinements: Energy & Vitality: Enlightenment',
}
