import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230213: IAudioItem = {
  category: 'buddhist',
  date: '2023-02-13',
  filename: '2023-02-13-candala-sutta-lay-practice.mp3',
  key: '9f5b51fd-507a-4714-96c0-88483b7f75e9',
  published: '2023-12-15',
  title: 'Candala Sutta (AN 5.175): Lay Practice',
}
