import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230220: IAudioItem = {
  category: 'buddhist',
  date: '2023-02-20',
  filename: '2023-02-20-courage.mp3',
  key: 'aec36af8-0c10-4417-b7c8-76c3f8161804',
  published: '2023-12-15',
  title: 'Courage',
}
