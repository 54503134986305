import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230127: IAudioItem = {
  category: 'buddhist',
  date: '2023-01-27',
  filename: '2023-01-27-1893-world-parliament.mp3',
  key: '91d7c45e-e3f3-4794-b8b1-c64a6b1133b2',
  published: '2023-11-24',
  title: 'History: 1893 World Parliament of Religions',
}
