import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240610: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-10',
  filename: '2024-06-10-causality.mp3',
  key: '34e38fb6-ab1f-46de-a725-4846992e6807',
  published: '2024-06-15',
  title: 'Causality',
  location: 'sangha-circle',
}
