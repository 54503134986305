import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230116: IAudioItem = {
  category: 'buddhist',
  date: '2023-01-16',
  filename: '2023-01-16-history-world-buddhist-sangha-council.mp3',
  key: '5b37c59e-94ca-4179-9dd9-492492966909',
  published: '2023-12-08',
  title: 'History: The World Buddhist Sangha Council',
}
