import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230130: IAudioItem = {
  category: 'buddhist',
  date: '2023-01-30',
  filename: '2023-01-30-1993-world-parliament.mp3',
  key: '14facb88-ec0b-4353-bab7-b703028dd30b',
  published: '2023-12-08',
  title: 'History: 1993 World Parliament of Religions',
}
