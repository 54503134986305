import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240318: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-18',
  filename: '2024-03-18-3-pure-precepts.mp3',
  key: 'ebe183f6-0da4-48e1-a2d2-fb467f2987c5',
  published: '2024-04-30',
  title: '3 Pure Precepts',
}
