import React from 'react'

import { AudioButtonList } from '../audio-button-list'
import { IAudioItem } from '../../lib/talks/audio-item.interface'
import { LimitedTalkListButton } from './limited-talk-list-button'

export function LimitedTalkList(props: {
  buttons: LimitedTalkListButton [],
  prefix: string,
  showPublishedDate?: boolean
  talks: IAudioItem[],
}) {
  const {
    buttons,
    prefix,
    showPublishedDate,
    talks,
  } = props

  const [
    selectedButton,
    setSelectedButton,
  ] = React.useState(buttons[0])


  const displayTalks: IAudioItem[] = React.useMemo(() => {
    return selectedButton.count > 0 ? talks.slice(0, selectedButton.count) : talks.slice()
  }, [selectedButton, talks])


  return (
    <>
      <div
        aria-label="Number of talks to display"
        className="btn-group m-3"
        role="group"
      >
        {buttons.map((x) => (
          <React.Fragment key={`lltl-fragment-${prefix}${x.uuid}`}>
            <input
              autoComplete="off"
              className="btn-check"
              id={`lltl-input-${prefix}${x.uuid}`}
              name={`lltl-group-name-${prefix}`}
              type="radio"
              checked={selectedButton.uuid === x.uuid}
              onChange={() => setSelectedButton(x)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor={`lltl-input-${prefix}${x.uuid}`}
            >
              {x.display}
            </label>
          </React.Fragment>
        ))}
      </div>

      {/*<AudioAccordionList*/}
      {/*  prefix={`ltl-${prefix}`}*/}
      {/*  list={displayTalks}*/}
      {/*  showPublishedDate={showPublishedDate ?? false}/>*/}
      <AudioButtonList
        list={displayTalks}
        prefix={'lay-precept'}
        showPublishedDate={showPublishedDate ?? false}
      />
    </>
  )
}
