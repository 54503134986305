import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230320: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-20',
  filename: '2023-03-20-dana-sutta.mp3',
  key: '25ce6c86-4c1c-4004-a34a-bcca1f872a06',
  published: '2023-12-15',
  title: 'Giving, Who Benefits (Dana Sutta)',
}
