import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240119: IAudioItem = {
  category: 'buddhist',
  date: '2024-01-19',
  filename: '2024-01-19-experience-vipassana.mp3',
  key: 'dbb5a395-5310-49b0-a4e5-fc3fbd9821ba',
  published: '2024-01-26',
  title: 'Experience Vipassana Meditation',
}
