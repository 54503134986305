import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240226: IAudioItem = {
  category: 'buddhist',
  date: '2024-02-26',
  filename: '2024-02-26-wisdom-and-view.mp3',
  key: 'adcefae8-6ede-4d8f-ac43-f1df0cdab58a',
  published: '2024-02-28',
  title: 'Wisdom and View',
  notes: `
<h5>Handout</h5>
<p>
The Three Characteristics of Existence, 
being a social self, 
and worldview come together to develop a sense of wisdom 
in how we relate to the moment-to-moment experiences of life.  
It is up to each of us to determine just what is our worldview.  
The next step, 
determine is our worldview based on a realistic view of the world around us.  
Then is our worldview in need of alteration or 
does it fit our view of how we are in relation to the world.
</p>
<ol>
</ol>
<li>What is your view of yourself?</li>
<li>What is your worldview, how you view yourself as part of the world?</li>
<p>
These are the questions you need to ask, 
and more importantly, 
to answer honestly.
</p>
`,
}
