import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240617: IAudioItem = {
  category: 'buddhist',
  date: '2024-06-17',
  filename: '2024-06-17-question-of-subahu.mp3',
  key: 'a359be12-ca66-4e52-b569-83d5eb780a5f',
  published: '2024-06-17',
  title: 'The Question of Subahu',
  location: 'sangha-circle',
}
