import React, { useState } from 'react'

export function AudioRawText(props: { rawText: string }) {
  const [show, setShow] = useState(false)

  const {rawText} = props
  if (!rawText) {
    console.log('rawText ', rawText)
    return null
  }

  const toHtml = rawText.replace(/(\n|\r)/g, '<br />\n')

  return show
    ? (
      <>
        <button className="btn btn-outline-primary m-3"
                onClick={() => setShow(!show)}
        >
          Hide Raw Machine Transcript
        </button>
        <div
          className="row text-secondary-emphasis bg-secondary-subtle border border-secondary-subtle rounded-3">
          <div className="col m-3">
            <h3>Raw Machine Transcript</h3>
            <p>
              An AI tool was used to convert the audio into text.
              Expect it to be full of mistakes.
            </p>
            {/*<pre> {rawText} </pre>*/}
            <div dangerouslySetInnerHTML={{__html: toHtml}}></div>
          </div>
        </div>
      </>
    )
    : (
      <>
        <button className="btn btn-outline-primary m-3"
                onClick={() => setShow(!show)}
        >
          Show Raw Machine Transcript
        </button>
      </>
    )
}
