import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20230310: IAudioItem = {
  category: 'buddhist',
  date: '2023-03-10',
  filename: '2023-03-10-6-perfection-intro-traditional.mp3',
  key: '2b24a4e6-606a-4c93-9be5-dd6c71665b58',
  published: '2023-12-15',
  title: '6 Refinements: Introduction (Traditional)',
}
