import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240701: IAudioItem = {
  category: 'buddhist',
  date: '2024-07-01',
  filename: '2024-07-01-how-to-bodhicitta.mp3',
  key: '825c6588-3df8-40e4-bfbc-9b5fd3ad8874',
  published: '2024-07-10',
  title: 'How To Practice Bodhicitta',
  location: 'sangha-circle',
}
