import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231204: IAudioItem = {
  category: 'buddhist',
  date: '2023-12-04',
  filename: '2023-12-04-relationships.mp3',
  key: '04c8453c-3478-4af6-befe-23de400f93a1',
  published: '2023-12-04',
  title: 'Intimate and Integral Relationships',

  notes: `
  <img class="rounded img-thumbnail" src="/assets/buddhist/2023-12-04-relationships-02.png" alt="relationship diagram 2">
</div>
<div class="col m-3">
  <img class="rounded img-thumbnail" src="/assets/buddhist/2023-12-04-relationships-01.png" alt="relationship diagram 1">
`,
}
