import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240531: IAudioItem = {
  category: 'buddhist',
  date: '2024-05-31',
  filename: '2024-05-31-happiness-contentment.mp3',
  key: 'e8676d46-b5d3-4b8e-9fb0-79ca188ba84d',
  published: '2024-06-15',
  title: 'Happiness and Contentment',
}
