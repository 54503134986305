import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231127: IAudioItem = {
  category: 'buddhist',
  date: '2023-11-27',
  filename: '2023-11-27-questions.mp3',
  key: '142c80a6-ce76-4d99-af29-d20180481024',
  published: '2023-11-27',
  title: 'Asking Questions',

  notes: `
  <h5>Homework</h5>
  <p>
  Spend some time and contemplate what is your "big question."
  It might be something simple like, "How am I doing?"
  Or it might be complex, 
  such as, 
  "How am I going to get to where I'm going?"
  </p>
`,
}
