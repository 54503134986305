import React from 'react'

import { LocationType } from '../../lib/talks/LocationType'

export function LocationLink(params: { location: LocationType }) {
  switch (params.location) {
    case 'buddha-center':
      return (<i>Buddha Center</i>)
    case 'sangha-circle':
      return (
        <a
          href="http://maps.secondlife.com/secondlife/Naberrie/82/153/53"
          target="_blank"
          rel="noreferrer"
        >
          Sangha Circle
        </a>
      )
    default:
      return null
  }
}
