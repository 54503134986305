import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20231023: IAudioItem = {
  category: 'buddhist',
  date: '2023-10-23',
  filename: '2023-10-23-6-refinements-meditation-overview.mp3',
  key: '53c6a527-dab5-403c-93af-f038f30fe115',
  published: '2023-12-05',
  title: '6 Refinements: Meditation: Mindfulness/Meditation Overview',
  notes: `
<h4>HOMEWORK:</h4>  

<p>
Try out (except full lotus) the different meditation postures for 5 minutes each. 
Just sit and watch your bodymind. 
Keep some notes about your experience. 
Determine which of the meditation postures you are more comfortable engaging. 
Once determined then, using that posture, just sit and watch your breath for ten minutes. 
</p>

<p>
Keep in mind that different postures may be more effective for different meditation exercises. 
For ex., if you find yourself drifting off to sleep during meditation (and it happens) try sitting in a different posture: seiza then half lotus, chair then Burmese, etc.
</p>

`
}
