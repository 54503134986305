import { IAudioItem } from '../../talks/audio-item.interface'

export const Talk20240325: IAudioItem = {
  category: 'buddhist',
  date: '2024-03-25',
  filename: '2024-03-25-four-ennobling-truths.mp3',
  key: '14cd7dfd-7b35-4d71-8b18-dc87080cc5e5',
  published: '2024-04-30',
  title: 'Four Ennobling Truths',
}
