import { AllTalksTable } from './all-talks-table'
import { IAudioItem } from '../talks/audio-item.interface'
import { PreceptClass2023Index } from './precept-class-2023-index'
import { TalksService } from '../talks/talks.service'

class BuddhaCenterDatabaseService extends TalksService {
  public readonly preceptClass2023: IAudioItem[]
  public readonly recentPublications: IAudioItem[]

  constructor(database: IAudioItem[]) {
    super(database.filter(x => x.category === 'buddhist'))

    this.preceptClass2023 = this.fromIndex(PreceptClass2023Index)

    this.recentPublications = this.getAllTalks()
      .filter(x => x.category === 'buddhist')
      .filter(x => !!x.published)
      .sort((a, b) => {
          const comparePrimary = b.published.localeCompare(a.published)
          const compareSecondary = b.date.localeCompare(a.date)

          // Sort on the first key.
          // If they are equal (0 value) then use the second comparison.
          return comparePrimary || compareSecondary
        },
      )
  }
}

export const buddhaCenterDatabaseService = new BuddhaCenterDatabaseService(AllTalksTable)
